/**
* Colors
**/

// Primary Color
$red: #e30a19;
$white: #FFFFFF;
$abbey: #333333;
$black: #000000;

// Infrastructure Colors
$silver: #CCCCCC;
$gallery: #EBEBEB;
$wild-sand: #F4F4F4;
$willow-brook: #F4F8F0;
$pipi: #FEF5CC;
$gray: #E7E7E7;

// Secondary Colors
$amethyst: #B64CBA;
$seance: #9C2AA0;
$finn: #5E2750;
$cerulean: #00B0CA;
$blue-lagoon: #007C92;
$buddha-gold: #A8B400;
$supernova: #FECB00;
$tangerine: #EB9700;

//Alert Colors
$seashell-peach: #FFF5F0;
$bridesmaid: #FEF0F0;

// Active State Colors
$dove-gray: #666666;
$red-oxide: #64050A;

// Background Color
$Alto: #D9D9D9;

//Catalogue
// $cataloguegrey: #626065;

// Text Shadows
$light-shadow: 1px 1px 0px #b7b0b0;
$box-shadow: 0 0 15px rgb(254 245 245 / 25%);

// Box Shadows
$light-box: 0 0 15px rgb(0 0 0 / 10%);
$medium-box : 0 0 15px rgb(0 0 0 / 25%);