@import "../../theme/index.module.scss";
@import "node_modules/susy/sass/_susy.scss";

.wrap {
  @include container();
  min-height: 1.45rem;
  // overflow: hidden
}

.article {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  //remove when banner is removed
}

.articles {
  @include span(12 of 12);
  @include susy-clearfix();
  position: relative;
 
}

.articles__gutter {
  width: gutter();
}

.articles__column {
  width: span(12 of 12);
}

.articles__item {
  width: span(12 of 12);
  margin-bottom: 1rem;
}

.stamp {
  position: absolute;
  right: 0;
}

.editor__article{
  z-index:3;
}


@include max-bp($small) {
  .wrap {
    margin-left: 1em;
    margin-right: 1em;
  }
  .articles {
     height: auto !important;
  }
  .articles__item {
    width: span(12 of 12);
    position: relative !important;
    top: 0px !important;
  }
}

@include bp($small) {
  .articles__column {
    width: span(6 of 12);
  }

  .articles__item {
    width: span(6 of 12);
  }
}



@include bp($medium) {
  .articles__column {
    width: span(4 of 12);
  }

  .articles__item {
    width: span(4 of 12);
  }

  .featured__article{
    width: span(8 of 12);
  }

  .editor__article{
    width: span(8 of 12);
  }

  .intro__article{
    width: span(8 of 12);
  }
}

@include bp($larger) {
  .articles__column {
    width: span(3 of 12);
  }

  .articles__item {
    width: span(3 of 12);
  }

  .intro__article{
    width: span(9 of 12);
  }
  .editor__article{
    width: span(9 of 12);
  }
}

.noData {
  color: $dove-gray;
  margin-bottom: 1.45rem;
  text-align: center;
}

.articles,
.articles .articles__item {
  transition-duration: .3s;
}

// .articles {
//   transition-property: height, width;
// }

.articles .articles__item {
  transition-property: transform, opacity;
}
